var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Page",class:_vm.checkUglyPage},[_c('div',{class:_vm.checkUglyIntro},[_c('div',{staticClass:"card-content"},[_c('label',{staticClass:"title is-centered is-size-3"},[_vm._v(" Hi! I'm Maxx ")]),_c('br'),_c('label',{staticClass:"subtitle is-centered"},[_vm._v(" A Computer Scientist ")]),_c('div',{class:{
          'columns': !_vm.uglify,
          '': _vm.uglify,
        }},[(!_vm.uglify)?_c('div',{staticClass:"column"}):_vm._e(),_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image is-is-square"},[(!_vm.uglify)?_c('img',{staticClass:"is-rounded",attrs:{"src":require("../../public/profile.jpg"),"alt":"this is me"}}):_c('img',{attrs:{"src":require("../../public/badface.jpg"),"alt":"BLARG BLARG"}})])]),_c('div',{staticClass:"column"})])])]),_c('br'),_c('div',{class:_vm.checkuglycard},[_c('div',{staticClass:"card-content"},[_c('div',{class:{
          'columns': !_vm.uglify,
          '': _vm.uglify,
        }},[_vm._m(0),_vm._m(1)])])]),_c('br'),_c('div',{class:_vm.checkuglycard},[_c('div',{staticClass:"card-content"},[_c('div',{class:{
          'columns': !_vm.uglify,
          '': _vm.uglify,
        }},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column"},[_c('p',{staticClass:"title is-family-sans-serif is-centered"},[_vm._v("I'm Here to Help.")]),_c('p',{staticClass:"is-family-sans-serif"},[_vm._v("I am currently available for freelance and full time work. If you think I would be a good fit or just want to say hi, please get in touch.")]),_c('br'),_c('a',{staticClass:"button",attrs:{"id":"email","href":'mailto:' + _vm.email}},[_vm._v("Message Me")])]),_c('div',{staticClass:"column"})])])]),_c('br')])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"title is-family-sans-serif is-centered"},[_vm._v("Who I am. ")]),_c('p',{staticClass:"is-family-sans-serif"},[_vm._v("I am a computer scientist/web developer based in Hood River Oregon. I graduated from Oregon State University in 2020. I work mainly in web development but I enjoy all things programming.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"title is-family-sans-serif is-centered"},[_vm._v("What I Can Do.")]),_c('p',{staticClass:"is-family-sans-serif"},[_vm._v("I mainly focus on full stack development so I am able to build entire websites from the user interface to the database. I am well versed in VueJs, Firebase, and AWS and I am very quick to pick up and learn new skills. ")])])}]

export { render, staticRenderFns }